import { popupStore } from "@/stores";
import { isValidEmail } from "@/utils/isValidEmail";
import Image from "next/image";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";

const SecondaryNewsletter = () => {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(true);

    const handleFormSubmit = async () => {
        setSubmitted(false);
        if (isValidEmail(email)) {
            setValidEmail(true);
            setIsLoading(true);
            popupStore.setContent("Subscription Added!", false, 2000);

            //async function here
            setIsLoading(false);
            setSubmitted(true);
            popupStore.setContent("Invalid Email", true, 2000);
        } else {
            setValidEmail(false);
        }
    };
    return (
        <section
            id="secondary-newsletter"
            className="bg-white px-[13px] py-5 lg:py-[60px] mt-[22px] lg:mt-[40px]"
        >
            <div className="max-w-7xl mx-auto flex flex-col gap-5 lg:grid grid-cols-2 lg:gap-[53px]">
                <div>
                    <h3 className="font-extrabold text-base lg:text-3xl text-my-dark-blue mb-[10px]">
                        Be the first to receive offers.
                    </h3>
                    <p className="font-light leading-[28px] text-xs lg:text-[20px] text-my-light-blue">
                        Subscribe now to receive alerts about the latest deals,
                        special offers, and promotions.
                    </p>
                </div>
                <form className="relative flex mt-[14px] lg:mt-[27px] items-center w-full justify-start  gap-[9px]">
                    <div
                        className={`w-full bg-[#F3F6F9] flex gap-[14px] items-center justify-start rounded-[14px] lg:rounded-[20px] border border-[#D9D9D9] overflow-clip py-5 px-4 ${
                            validEmail ? "" : "border-red-500"
                        } ${submitted ? "border-green-500" : ""}`}
                    >
                        <div className="lg:h-[24px] lg:w-[24px] h-[18px] w-[18px]">
                            <Image
                                width={24}
                                height={24}
                                src="/newsletter/mail.svg"
                                alt="Mail icon"
                            />
                        </div>
                        <input
                            type="email"
                            className="bg-[#F3F6F9]
                            h-full max-h-[64px] w-full  text-xs lg:text-xl  text-my-dark-blue placeholder:text-base lg:placeolder:text-[20px] placeholder:font-[400] placeholder:text-my-dark-blue focus:ring-0 focus:border-none focus:outline-none border-none "
                            placeholder="Enter email address"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {isLoading && (
                            <div className="translate-x-6">
                                <ColorRing
                                    colors={[
                                        "#0057ff",
                                        "#0057ff",
                                        "#0057ff",
                                        "#0057ff",
                                        "#0057ff",
                                    ]}
                                    height={40}
                                    width={40}
                                />
                            </div>
                        )}
                    </div>

                    <button
                        name="submit"
                        type="submit"
                        className="font-semibold text-xs lg:text-[18px] h-full py-[21px] px-9 text-white transition rounded-[10px] lg:rounded-[20px] seconday-newsletter-btn-gradient "
                        onClick={(e) => {
                            e.preventDefault();
                            handleFormSubmit();
                        }}
                    >
                        Subscribe
                    </button>
                </form>
            </div>
        </section>
    );
};

export default SecondaryNewsletter;
